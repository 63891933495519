import sp1 from "../assets/Sponsor1.jpg";
import sp2 from "../assets/Sponsor2.jpg";
import sp3 from "../assets/Sponsor3.jpg";
import sp4 from "../assets/Sponsor4.jpg";
import sp5 from "../assets/Sponsor5.avif";
import sp6 from "../assets/Sponsor6.jpg";
import sp7 from "../assets/Sponsor7.jpg";
import sp8 from "../assets/Sponsor8.jpg";

const data = [
  {
    img: sp1,
    data: "Techno-cultural fest of best technical institute in the region fully funded by MHRD.",
  },
  {
    img: sp2,
    data: "Appearance of sponsor name and logo on website and merchandises of SOLASTA’23.",
  },
  {
    img: sp5,
    data: "Space will be provided to showcase products and services and engage with attendees.",
  },

  {
    img: sp4,
    data: "Publicity and on campus branding through social media(posters to be placed across the city) and 50+ college fest ambassador network.",
  },
  {
    img: sp3,
    data: "Connect with 10k+ young and energetic college students from across the region including that from premier institutions like IITs, IIITs and NITs.",
  },

  {
    img: sp6,
    data: "Publicity and brand awareness through goodies exchange and event catalogues for attendees",
  },
  {
    img: sp7,
    data: "Support a cause : Allocate a portion of the sponsorship funds to a local charity focused on empowering underprivileged children.",
  },
  {
    img: sp8,
    data: "Get insights into brand recognition and awareness through analytics from participant/attendee feedback.",
  },
];

const sponsors = [
  {
    img: "pw.jpg",
    type: "Title Sponsor",
  },
  {
    img: "unstop.jpg",
    type: "Registration partner",
  },
];

export default sponsors;
